import { json } from "@remix-run/node";
import type { ActionFunctionArgs, MetaFunction } from "@remix-run/node";
import { Outlet, useLoaderData } from "@remix-run/react";

import { Footer } from "~/components/marketing/lib/Footer";
import { Header } from "~/components/marketing/lib/Header";
import { getUserId } from "~/lib/auth.server";

export const meta: MetaFunction = () => {
  return [
    { title: "CandidateStream" },
    { name: "description", content: "Welcome to CandidateStream!" },
  ];
};

export async function loader({ request }: ActionFunctionArgs) {
  // await requireRecentVerification(request);
  const userId = await getUserId(request);

  return json({ isLoggedIn: !!userId });
}

export default function MarketingLayout() {
  const { isLoggedIn } = useLoaderData<typeof loader>();

  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
