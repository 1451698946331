import { Fragment } from "react";

import { Popover, Transition } from "@headlessui/react";
import { Link } from "@remix-run/react";

import { Button } from "~/components/marketing/lib/Button";
import { Container } from "~/components/marketing/lib/Container";
import { NavLink } from "~/components/marketing/lib/NavLink";
import Logo from "~/components/ui/logo";
import { cn } from "~/lib/utils";

function MobileNavLink({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <Popover.Button as={Link} to={to} className="block w-full p-2">
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={cn("origin-center transition", open && "scale-90 opacity-0")}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={cn(
          "origin-center transition",
          !open && "scale-90 opacity-0",
        )}
      />
    </svg>
  );
}

function MobileNavigation({ isLoggedIn }: { isLoggedIn: boolean }) {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink to="#features">Features</MobileNavLink>
            <MobileNavLink to="#testimonials">Testimonials</MobileNavLink>
            <MobileNavLink to="#faqs">FAQs</MobileNavLink>
            <MobileNavLink to="#pricing">Pricing</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            {!isLoggedIn && (
              <MobileNavLink to="/auth/login">Sign in</MobileNavLink>
            )}
            {isLoggedIn && (
              <MobileNavLink to="/app/home">Go to dashboard</MobileNavLink>
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header({ isLoggedIn }: { isLoggedIn: boolean }) {
  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <div className="flex flex-shrink-0 items-center">
                <Logo
                  variant="mark-word"
                  className="mr-2 h-8 sm:h-10 w-auto text-card-foreground md:h-12"
                />
              </div>
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#testimonials">Testimonials</NavLink>
              <NavLink href="#faqs">FAQs</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {!isLoggedIn && (
              <>
                <div className="hidden md:block">
                  <NavLink href="/auth/login">Sign in</NavLink>
                </div>
                <Button to="#emailSignup" color="blue">
                  <span>
                    Get Updates
                    {/* <span className="hidden lg:inline">today</span> */}
                  </span>
                </Button>
              </>
            )}
            {isLoggedIn && (
              <Button to="/app/home" color="blue">
                <span className="hidden lg:inline">Go to&nbsp;</span> Dashboard
              </Button>
            )}
            <div className="-mr-1 md:hidden">
              <MobileNavigation isLoggedIn={isLoggedIn} />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
